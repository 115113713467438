import {
  ref,
  computed,
  readonly,
} from '@vue/composition-api'
import instruments from '@/data/instruments.json'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import useInstruments from '../useInstruments'
import processStrategies from './processStrategies'

// strategy state
const strategies = ref({
  pageSize: 1200,
  active: 'Hermes',
  list: [
    { index: 0, name: 'Human' },
    { index: 1, name: 'Hybrid' },
    { index: 2, name: 'Hermes' },
  ],
  followed: [],
  human: JSON.parse(JSON.stringify(instruments.array)),
  hybrid: JSON.parse(JSON.stringify(instruments.array)),
  hermes: JSON.parse(JSON.stringify(instruments.array)),
  single: {
    data: {},
    info: null,
  },
})

// setSingleStrategyAbout(state, payload) {
//   state.single.strategies.info = payload
//   console.log('sing strat added', state.single.strategies.info)
//   state.loading.processes -= 1
//   if (state.loading.processes === 0) {
//     console.log('setSingleStrategyAbout loading process', state.loading.processes)
//     state.loading.status = false
//   }
// },
//     addSingleStrategy(state, payload) {
//       const { strategy, id } = payload
//       state.single.strategies.data = { ...strategy, stratId: id }
//     },
//     addFollowedStrategies(state, payload) {
//       const { strategies, strategyIds } = payload
//       state.strategies.followed.ids = strategyIds
//       state.strategies.followed.strategies = strategies
//     },
//     async getSingleStrategy({ commit }, payload) {
//       // eslint-disable-next-line prefer-const
//       let { strategyType, id } = payload
//       if (strategyType === 'hermes') {
//         strategyType = 'agent'
//       }
//       console.log('getStrat store', strategyType, id)
//       axios.get(`${hermesUrl}/${strategyType}/${id}`)
//         .then(res => {
//           console.log('single strategy', res.data)
//           commit('addSingleStrategy', { strategy: res.data, id })
//         })
//         .catch(err => {
//           console.error(err)
//         })
//     },
//     async getFollowedStrategies({ commit }, authId) {
//       const data = await graphCMS.request(
//         gql`
//           {
//             customer(where: {authId: "${authId}"}) {
//               strategies(first: 100) {
//                 strategyString
//               }
//               authId
//             }
//           }`,
//       )
//       const { strategies } = data.customer
//       const strategyIds = []
//       strategies.forEach(strategy => {
//         strategyIds.push(strategies.strategyString)
//       })
//       commit('addFollowedStrategies', { strategies, strategyIds })
//     },

export default function useStrategies() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { instrumentActive } = useInstruments()
  const instrumentType = computed(() => instrumentActive.value.name)
  const strategyType = computed(() => strategies.value.active.toLowerCase())
  const { http } = useApollo()

  async function getHumanStrategies() {
    const { pageSize } = strategies.value
    let offset = 0
    let raw
    if (strategies.value[strategyType.value][instrumentType.value].length > 0) {
      offset = strategies.value[strategyType.value][instrumentType.value].length
    }
    // need to add instrument filter when able
    await http.value.query({
      query: gql`
        query getHumanStrategies {
          ${frontSchema}metricses(limit: ${pageSize}, offset: ${offset}, order_by: {total_return: desc}) {
            avg_activation
            avg_max_neg
            avg_max_pos
            avg_return
            avg_sl
            avg_tp
            begin_time
            dataset_size
            end_time
            id
            max_sl
            max_tp
            min_sl
            min_tp
            total_return
            trades_lost
            trades_won
            label
          }
      }
      `,
    })
      .then(result => {
        if (result.data[`${frontSchema}metricses`].length > 0) {
          raw = result.data[`${frontSchema}metricses`]
        } else {
          // no more agents in database
          // provide feedback
        }
      })
      .catch(err => console.error('getHumanStrategies', err))
    return raw
  }

  async function getHybridStrategies() {
    const { pageSize } = strategies.value
    let offset = 0
    let raw
    if (strategies.value[strategyType.value][instrumentType.value].length > 0) {
      offset = strategies.value[strategyType.value][instrumentType.value].length
    }
    // need to add instrument filter when able
    await http.value.query({
      query: gql`
        query getHybridStrategies {
          ${frontSchema}_agents(limit: ${pageSize}, offset: ${offset}, order_by: {total_return: desc}) {
            avg_return
            avg_sl
            avg_tp
            max_sl
            max_tp
            min_sl
            min_tp
            lookahead_count
            lookbehind_count
            rules_count
            total_return
            trades_lost
            trades_won
            id
            dataset_size
            end_time
            begin_time
            avg_activation
            avg_max_neg
            avg_max_pos
          }
      }
      `,
    })
      .then(result => {
        if (result.data[`${frontSchema}_agents`].length > 0) {
          raw = result.data[`${frontSchema}_agents`]
        } else {
          // no more agents in database
          // provide feedback
        }
      })
      .catch(err => console.error('gethybridStrategies', err))
    return raw
  }

  async function getHermesStrategies() {
    const { pageSize } = strategies.value
    let offset = 0
    let raw
    if (strategies.value[strategyType.value][instrumentType.value].length > 0) {
      offset = strategies.value[strategyType.value][instrumentType.value].length
    }
    console.log('getTrades', strategyType.value, instrumentType.value, offset, pageSize)
    // need to add instrument filter when able
    await http.value.query({
      query: gql`
        query getHermesStrategies {
          mlfx_agents(order_by: {metric: {avg_return: desc}}, where: {strategy: {instrument: {name: {_eq: "${instrumentType.value}"}}}}) {
            creation_begin_time
            creation_end_time
            id
            lookahead
            lookbehind
            rules_count
            retired
            strategy {
              instrument {
                name
                id
              }
              method {
                id
                name
              }
            }
            metric {
              avg_activation
              avg_max_neg
              avg_max_pos
              avg_return
              avg_revenue
              avg_sl
              avg_tp
              begin_time
              dataset_size
              end_time
              id
              max_sl
              max_tp
              min_sl
              min_tp
              stdev_activation
              stdev_max_neg
              stdev_max_pos
              stdev_revenue
              stdev_sl
              stdev_tp
              total_return
              total_revenue
              trades_lost
              trades_won
            }
          }
      }
      `,
    })
      .then(result => {
        if (result.data[`${frontSchema}_agents`].length > 0) {
          raw = result.data[`${frontSchema}_agents`]
        } else {
          // no more agents in database
          // provide feedback
        }
      })
      .catch(err => console.error('getHermesStrategies', err))
    return raw
  }

  async function getStrategies() {
    let processed
    let rawStrategies

    if (strategyType.value === 'human') {
      rawStrategies = await getHumanStrategies()
      processed = await processStrategies.human(rawStrategies)
    } else if (strategyType.value === 'hybrid') {
      rawStrategies = await getHybridStrategies()
      processed = await processStrategies.hybrid(rawStrategies)
    } else if (strategyType.value === 'hermes') {
      rawStrategies = await getHermesStrategies()
      processed = await processStrategies.hermes(rawStrategies)
    }
    strategies.value[strategyType.value][instrumentType.value].push(...processed)
  }

  function setStrategyActive(stratType) {
    strategies.value.active = stratType
  }

  return {
    strategies: computed(() => strategies.value[strategyType.value][instrumentType.value]),
    strategyActive: computed(() => strategies.value.active),
    strategyList: readonly(strategies.value.list),
    setStrategyActive,
    getStrategies,
  }
}
