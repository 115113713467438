import toPIPs from '@/mixins/toPIPs'

async function processHumanStrategies(rawStrategies) {
  return rawStrategies.map(x => {
    const totalTrades = +x.trades_lost + +x.trades_won
    const accuracy = Math.round((x.trades_won / totalTrades) * 1000) / 10
    const parsedRet = parseFloat(x.avg_return)
    // const RR = `1 / ${Math.abs(x.testAvgTp / x.testAvgSl).toFixed(2)}`
    // const daysDataset = x.dataset_size / 96 // 24*4 = 96 for 15M timeframe
    const weeksDataset = x.dataset_size / (96 * 5)
    const frequency = Math.round(totalTrades / weeksDataset)
    const indicators = x.label.replace('human.', '')

    const instrument = 'AUD_USD'

    return {
      // don't use toFixed or formatting here, value needs to be number to sort
      // pairing: x.instrument.replace(/_/g, ' / '),
      // activation: parsedAct,
      // avgActivation: parseFloat(x.avgActivation) * 100,
      // trades: x.
      // avgRR: RR,
      // timeframe: x.timeframe,
      id: x.id,
      dataset: x.dataset_size,
      reward: Math.abs(x.avg_tp) / Math.abs(x.avg_sl),
      pipsTP: toPIPs(instrument, Math.abs(x.avg_tp)),
      pipsSL: toPIPs(instrument, Math.abs(x.avg_sl)),
      accuracy,
      avgReturn: parsedRet,
      indicators: indicators.replaceAll('-', ' '),
      totalReturn: parseFloat(x.total_return),
      totalTrades,
      tradesLost: Number(x.trades_lost),
      tradesWon: Number(x.trades_won),
      frequency: Number(frequency),
      instrument,
      strategyType: 'human',
      label: x.label,
    }
  })
}

async function processHybridStrategies(rawStrategies) {
  return rawStrategies.map(x => {
    const totalTrades = +x.tradesLost + +x.tradesWon
    const accuracy = Math.round((x.tradesWon / totalTrades) * 1000) / 10
    const parsedRet = parseFloat(x.avg_return)
    // const RR = `1 / ${Math.abs(x.testAvgTp / x.testAvgSl).toFixed(2)}`
    // const daysDataset = x.dataset_size / 96 // 24*4 = 96 for 15M timeframe
    const weeksDataset = x.dataset_size / (96 * 5)
    const frequency = Math.round(totalTrades / weeksDataset)
    const indicators = x.name.replace('hybrid.', '')

    return {
      // don't use toFixed or formatting here, value needs to be number to sort
      // pairing: x.instrument.replace(/_/g, ' / '),
      id: x.id,
      dataset: x.dataset_size,
      reward: Math.abs(x.avg_tp) / Math.abs(x.avg_tp),
      pipsTP: toPIPs(x.instrument, Math.abs(x.avg_tp)),
      pipsSL: toPIPs(x.instrument, Math.abs(x.avg_tp)),
      accuracy,
      avgReturn: parsedRet,
      iterations: x.iterations,
      evolved: x.evolved,
      indicators: indicators.replaceAll('-', ' '),
      // activation: parsedAct,
      // avgActivation: parseFloat(x.avgActivation) * 100,
      totalReturn: parseFloat(x.total_return),
      // trades: x.
      totalTrades,
      tradesLost: Number(x.tradesLost),
      tradesWon: Number(x.tradesWon),
      frequency: Number(frequency),
      timeframe: x.timeframe,
      // avgRR: RR,
      instrument: x.instrument,
      strategyType: 'hybrid',
      label: x.name,
    }
  })
}

async function processHermesStrategies(rawStrategies) {
  // let buyAgent = 0
  // let sellAgent = 0
  const data = rawStrategies.map(x => {
    const totalTrades = +x.trades_lost + +x.trades_won
    const accuracy = Math.round((x.trades_won / totalTrades) * 1000) / 10
    const parsedRet = parseFloat(x.avg_return)
    // const RR = `1 / ${Math.abs(x.testAvgTp / x.testAvgSl).toFixed(2)}`
    // const daysDataset = x.dataset_size / 96 // 24*4 = 96 for 15M timeframe
    const weeksDataset = x.dataset_size / (96 * 5)
    const frequency = Math.round(totalTrades / weeksDataset)
    const instrument = 'AUD_USD'
    let agentDirection
    // if (x.avg_tp > 0) {
    //   buyAgent += 1
    //   agentDirection = 'BULLISH'
    // } else if (x.avg_tp < 0) {
    //   sellAgent += 1
    //   agentDirection = 'BEARISH'
    // }
    return {
      avgMaxPos: toPIPs(instrument, Math.abs(x.avg_max_pos)),
      avgMaxNeg: toPIPs(instrument, Math.abs(x.avg_max_neg)),
      agentDirection,
      accuracy,
      avgReturn: parsedRet,
      avgActivation: parseFloat(x.avg_activation) * 100,
      // don't use toFixed or formatting here, value needs to be number to sort
      id: x.id,
      dataset: x.dataset_size,
      // reward: Math.abs(x.avg_tp) / Math.abs(x.avg_tp),
      pipsTP: toPIPs(instrument, Math.abs(x.avg_tp)),
      pipsSL: toPIPs(instrument, Math.abs(x.avg_sl)),
      maxTP: toPIPs(instrument, Math.abs(x.max_tp)),
      maxSL: toPIPs(instrument, Math.abs(x.max_sl)),
      minTP: toPIPs(instrument, Math.abs(x.min_tp)),
      minSL: toPIPs(instrument, Math.abs(x.min_sl)),
      indicators: null,
      totalReturn: parseFloat(x.total_return),
      stdevActivation: parseFloat(x.stdev_activation) * 100,
      totalRules: x.rules_count,
      totalTrades,
      tradesLost: Number(x.trades_lost),
      tradesWon: Number(x.trades_won),
      frequency: Number(frequency),
      // avgRR: RR,
      instrument: instrument.replace(/_/g, ' / '),
      strategyType: 'hermes',
      timeframe: x.timeframe,
      label: x.name,
      lookahead: x.lookahead_count,
      lookbehind: x.lookbehind_count,
      creationBegin: x.creation_begin_time,
      trainingEnd: x.end_time,
    }
  })
  return data
}

const processStrategies = {
  human: processHumanStrategies,
  hybrid: processHybridStrategies,
  hermes: processHermesStrategies,
}

export default processStrategies
