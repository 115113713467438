export default function toPIPs(instrument, quantity) {
  const x100 = instrument.search(/JPY/) >= 0
    || instrument.search(/HUF/) >= 0
    || instrument.search(/KRW/) >= 0
    || instrument.search(/THB/) >= 0
  if (x100) {
    return (quantity * 100).toFixed(1)
  }

  const x1000 = instrument.search(/CZK/) >= 0
    || instrument.search(/CNY/) >= 0
    || instrument.search(/INR/) >= 0
  if (x1000) {
    return (quantity * 1000).toFixed(1)
  }

  // default PIP value
  return (quantity * 10000).toFixed(1)
}
